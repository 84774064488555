<template>
  <div
    class="c_accordion_tab"
    :class="{
      'c_accordion_tab--mobile': !disabled && mobile,
      'c_accordion_tab--open': isOpen,
      'c_accordion_tab--disabled': disabled,
    }"
  >
    <Button
      v-if="!disabled"
      :aria-expanded="isOpen ? 'true' : 'false'"
      unstyle
      reset
      class="c_accordion_tab__header"
      :class="showArrows && mobile ? 'c_accordion_tab__header--flex' : ''"
      @onClick="onHeaderClicked"
      :aria-label="!disabled ? $t('Toggle Accordion') : false"
    >
      <slot name="header" />
      <IconSVG
        v-if="!isOpen && showArrows && mobile"
        height="20"
        width="30"
        class="c_accordion_tab__header__arrow c_accordion_tab__header__arrow--open"
        handle="arrow_down"
        aria-hidden="true"
      />
      <IconSVG
        v-else-if="isOpen && showArrows && mobile"
        height="20"
        width="30"
        class="c_accordion_tab__header__arrow c_accordion_tab__header__arrow--close"
        handle="arrow_up"
        aria-hidden="true"
      />
    </Button>
    <div ref="content" v-if="!disabled" class="c_accordion_tab__content" v-show="isOpen">
      <slot name="content" />
    </div>
    <div ref="content" v-else>
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';

export default {
  components: {
    Button,
    IconSVG,
  },
  data() {
    return {
      clickListener: null,
      isOpen: false,
      toggleTabHandler: false,
    };
  },
  props: {
    closeAction: Function,
    closeActionArgs: Array,
    disabled: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false },
    open: { type: Boolean, default: false },
    openAction: Function,
    openActionArgs: Array,
    showArrows: {
      type: Boolean,
      default: true,
    },
    toggleSelf: { type: Boolean, default: false },
  },
  methods: {
    onHeaderClicked() {
      if (this.toggleTabHandler) {
        this.toggleTabHandler(this._uid);
      } else if (this.toggleSelf) {
        this.isOpen = !this.isOpen;
      }
    },
    onOpenCallback(thisAccordionTab) {
      if (this.openAction) {
        if (this.openActionArgs) {
          this.openAction(thisAccordionTab, ...this.openActionArgs);
        } else {
          this.openAction(thisAccordionTab);
        }
      }
    },
    onCloseCallback(thisAccordionTab) {
      if (this.closeAction) {
        if (this.closeActionArgs) {
          this.closeAction(thisAccordionTab, ...this.closeActionArgs);
        } else {
          this.closeAction(thisAccordionTab);
        }
      }
    },
  },
  updated() {
    if (this.isOpen) {
      this.onOpenCallback(this);
    } else {
      this.onCloseCallback(this);
    }
  },
  created() {
    if (this.open) {
      this.isOpen = true;
    }
  },
};
</script>

<style lang="scss">
.c_accordion_tab {
  $self: &;

  @at-root #{$self}--mobile {
    background-color: $color_gray_235;
    border-bottom: 1px solid $color_white;

    &:last-child {
      border-bottom: none;
    }

    @at-root #{$self}--open#{&} {
      padding: 0 14px 14px;
      background-color: $color_white;
      border-bottom-color: $color_gray_235;
    }
  }

  @at-root #{$self}__header {
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;

    @at-root #{&}--flex {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }

    @at-root #{&}__arrow {
      padding-left: px_to_rem(20);
      flex: 0 0 px_to_rem(40);
    }

    @at-root #{$self}--mobile & {
      padding: 14px 30px 14px 14px;
      position: relative;
      @include font('graphik_semibold');
      text-transform: uppercase;

      @at-root #{$self}--open#{&} {
        padding: 14px 16px 10px 0;
      }

      /*&:before {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% - 0.4rem);
        right: 20px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 7px solid $color_black;

        @at-root #{$self}--open#{&} {
          right: 5px;
          transform: rotate(90deg);
        }
      }*/
    }
  }
}
</style>
