<template>
  <dl class="c_accordion">
    <slot />
  </dl>
</template>

<script>
import { log } from 'JS/global.js';

let activeTab = null;
const clickOustideListener = (event) => {
  const { content } = activeTab?.$refs;
  if (content && content !== event.target && !content.contains(event.target)) {
    activeTab.isOpen = false;
    document.removeEventListener('click', clickOustideListener);
    activeTab = null;
  }
};
export default {
  data() {
    return {
      tabs: [],
    };
  },
  props: {
    firstTabOpen: { type: Boolean, default: true },
  },
  methods: {
    toggleTabHandler(uid) {
      log('Opening Accordion Tab', uid);
      document.removeEventListener('click', clickOustideListener);
      this.tabs.forEach((tab) => {
        tab.isOpen = tab._uid === uid ? !tab.isOpen : false;
      });
      activeTab = this.tabs.find((tab) => tab.isOpen);
      if (activeTab) {
        setTimeout(() => document.addEventListener('click', clickOustideListener));
      }
    },
  },
  mounted() {
    let children = this.$children;
    while (children && !this.tabs.length) {
      // Find if children contain instance of AccordionTab
      if (
        children.some((item) => {
          return item.$options._componentTag === 'AccordionTab';
        })
      ) {
        // Sets tabs to identified AccordionTab components
        this.tabs = children.filter((item) => {
          return item.$options._componentTag === 'AccordionTab';
        });
        log('tabs', this.tabs);
      }
      children = children[0].$children;
    }

    if (this.tabs) {
      const tabTotal = this.tabs.length;
      for (let i = 0; i < tabTotal; i++) {
        this.tabs[i].isOpen = this.firstTabOpen && i === 0;
        this.tabs[i].toggleTabHandler = this.toggleTabHandler;
      }
    }
  },
};
</script>
