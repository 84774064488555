var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Box',_vm._b({staticClass:"c_video",class:{ 'c_video--background': _vm.background },attrs:{"element-type":_vm.elementType}},'Box',_vm.box,false),[(_vm.source === 'file')?_c('LazyLoad',{class:{ c_video_bg__video: _vm.background },attrs:{"after-load":{ src: _vm.src || false },"autoplay":_vm.background ? true : _vm.autoplay,"controls":_vm.background ? false : _vm.controls,"element-type":"video","enable":_vm.lazyLoad,"loop":_vm.background ? true : _vm.loop,"muted":_vm.background ? true : _vm.muted,"playsinline":_vm.background ? 'playsinline' : _vm.playsinline ? 'playsinline' : false,"poster":_vm.poster || false,"title":_vm.title}}):(_vm.source === 'youtube')?_c('LazyLoad',{attrs:{"after-load":{
      src: _vm.videoId
        ? `https://www.youtube.com/embed/${_vm.videoId}?rel=0&modestbranding=1&enablejsapi=1${
            _vm.autoplay ? '&autoplay=1' : ''
          }`
        : false,
    },"allow":_vm.autoplay ? 'autoplay' : null,"allowfullscreen":"","check-for-native-lazy-load":_vm.lazyLoad,"element-type":"iframe","enable":_vm.lazyLoad,"frameborder":"0","height":"480","loading":_vm.loading,"width":"853","title":_vm.title}}):(_vm.source === 'vimeo')?_c('LazyLoad',{attrs:{"after-load":{ src: _vm.videoId ? `https://player.vimeo.com/video/${_vm.videoId}?title=0&byline=0&portrait=0` : false },"allowfullscreen":"","check-for-native-lazy-load":_vm.lazyLoad,"element-type":"iframe","enable":_vm.lazyLoad,"frameborder":"0","height":"281","loading":_vm.loading,"mozallowfullscreen":"","webkitallowfullscreen":"","title":_vm.title,"width":"500"}}):(_vm.source === 'youku')?_c('LazyLoad',{attrs:{"after-load":{ src: _vm.videoId ? `https://player.youku.com/embed/${_vm.videoId}` : false },"allowfullscreen":"","check-for-native-lazy-load":_vm.lazyLoad,"element-type":"iframe","enable":_vm.lazyLoad,"frameborder":"0","height":"281","loading":_vm.loading,"mozallowfullscreen":"","webkitallowfullscreen":"","title":_vm.title,"width":"500"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }