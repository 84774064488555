<template>
  <div class="c_disclaimer_footnote" ref="c_disclaimer_footnote">
    <div class="c_disclaimer_footnote-container" v-if="disclaimers[0]">
      <div class="c_wrapper">
        <div v-for="(disclaimer, key) in disclaimers" :key="key">
          <div class="c_disclaimer_footnote-item" v-html="disclaimer.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { openPdfsInNewTab } from 'JS/global.js';
export default {
  props: {
    disclaimers: Array,
  },
  mounted() {
    openPdfsInNewTab(this.$refs.c_disclaimer_footnote);
  },
};
</script>

<style lang="scss">
.c_disclaimer_footnote {
  $self: &;
  @at-root #{$self}-container {
    padding-top: 25px;
    padding-bottom: 15px;
    font-size: 12px;
    @at-root #{$self}-item {
      /* Add spacing between disclaimers */
      padding-bottom: 10px;
    }
  }
}
</style>
