import { landingPageContentGql } from './matrix_blocks/landingPageContentGql.js';

const pageGql = `title
... on home_home_Entry{
  ${landingPageContentGql}
}
seomatic(asArray: true) {
  metaTitleContainer
  metaLinkContainer
  metaScriptContainer
  metaJsonLdContainer
  metaTagContainer
}`;

export const homeGql = `query($site: String) {
  entry(
    section: ["home"]
    site: [$site]
    uri: "__home__"
  ) {
    ${pageGql}
  }
}`;

export const homeGenerateGql = function (sitesString = `$site`) {
  return `query {
    entries(
      section: ["home"]
      site: [${sitesString}]
      limit: null
      uri: "__home__"
    ) {
      sectionHandle
      siteId
      uri
      ${pageGql}
    }
  }`;
};
