<template>
  <Box :element-type="elementType" class="c_video" :class="{ 'c_video--background': background }" v-bind="box">
    <LazyLoad
      :after-load="{ src: src || false }"
      :autoplay="background ? true : autoplay"
      :class="{ c_video_bg__video: background }"
      :controls="background ? false : controls"
      element-type="video"
      :enable="lazyLoad"
      :loop="background ? true : loop"
      :muted="background ? true : muted"
      :playsinline="background ? 'playsinline' : playsinline ? 'playsinline' : false"
      :poster="poster || false"
      :title="title"
      v-if="source === 'file'"
    />
    <LazyLoad
      :after-load="{
        src: videoId
          ? `https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1&enablejsapi=1${
              autoplay ? '&autoplay=1' : ''
            }`
          : false,
      }"
      :allow="autoplay ? 'autoplay' : null"
      allowfullscreen
      :check-for-native-lazy-load="lazyLoad"
      element-type="iframe"
      :enable="lazyLoad"
      frameborder="0"
      height="480"
      :loading="loading"
      width="853"
      :title="title"
      v-else-if="source === 'youtube'"
    />
    <LazyLoad
      :after-load="{ src: videoId ? `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0` : false }"
      allowfullscreen
      :check-for-native-lazy-load="lazyLoad"
      element-type="iframe"
      :enable="lazyLoad"
      frameborder="0"
      height="281"
      :loading="loading"
      mozallowfullscreen
      webkitallowfullscreen
      :title="title"
      width="500"
      v-else-if="source === 'vimeo'"
    />
    <LazyLoad
      :after-load="{ src: videoId ? `https://player.youku.com/embed/${videoId}` : false }"
      allowfullscreen
      :check-for-native-lazy-load="lazyLoad"
      element-type="iframe"
      :enable="lazyLoad"
      frameborder="0"
      height="281"
      :loading="loading"
      mozallowfullscreen
      webkitallowfullscreen
      :title="title"
      width="500"
      v-else-if="source === 'youku'"
    />
  </Box>
</template>

<script>
import Box from 'Components/box/Box.vue';
import LazyLoad from 'Components/lazy_load/LazyLoad.vue';
import { defineComponent, reactive, toRefs, useMeta } from '@nuxtjs/composition-api';

export default defineComponent({
  head: {},
  components: {
    Box,
    LazyLoad,
  },
  props: {
    autoplay: { type: Boolean, default: false },
    background: { type: Boolean, default: false },
    box: {
      type: Object,
      default: () => {
        return {};
      },
    },
    controls: { type: Boolean, default: true },
    elementType: { type: String, default: 'div' },
    loading: { type: String, default: 'lazy' }, // lazy, auto, eager
    loop: { type: Boolean, default: false },
    muted: { type: Boolean, default: false },
    playsinline: { type: Boolean, default: false },
    poster: String,
    source: { type: String, default: 'file' }, // file, youtube, vimeo
    src: String,
    title: {
      type: String,
      default: '',
    },
    videoId: String,
  },
  setup(props) {
    const state = reactive({
      lazyLoad: false,
      playerUrl: false,
    });

    useMeta(() => {
      if (props.source === 'youtube') {
        return { script: [{ hid: 'yt-iframe-api', src: 'https://www.youtube.com/iframe_api' }] };
      }

      return {};
    });

    return { ...toRefs(state) };
  },
  created() {
    this.lazyLoad = this.loading === 'lazy';
  },
});
</script>
